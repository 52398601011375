import { ConversionExchangeRate, CurrencyBalance, OtherCurrencyBalance } from "../../components/l10n/Currency.tsx";
import { type BillingAccountCardWithData } from "./billingCardsQuery.ts";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { MethodBase } from "./PaymentMethod.tsx";
import { getCalculatedFees, getCountryCurrencyFees } from "./topup/fees.ts";

export function ConversionMessage({
    account,
    amount,
    fees,
    card,
}: {
    account: EBillingAccount;
    amount: number;
    fees: MethodBase["fee"];
    card: BillingAccountCardWithData | undefined;
}) {
    const charge_currency_conversion = fees?.charge_currency_conversion;
    if (!charge_currency_conversion) {
        return;
    }

    const conversion = getCountryCurrencyFees(
        account,
        charge_currency_conversion,
        card?.parsedAdditionalData.processor_data?.country,
    );
    if (!conversion) {
        return;
    }

    const { total } = getCalculatedFees(fees, amount, account.account.vat_percentage);
    const convertedAmount = total * conversion.exchange_rate;
    return (
        <div className="font-size-small color-muted pl-4">
            Your actual charge will be{" "}
            <OtherCurrencyBalance currency={conversion.to_currency} value={convertedAmount} />, with exchange rate of{" "}
            <CurrencyBalance value={1} /> ={" "}
            <ConversionExchangeRate currency={conversion.to_currency} value={conversion.exchange_rate} />.<br />
            The final exchange rate and charge amount will be determined at the moment of actual payment.
        </div>
    );
}
